import { createRoot } from 'react-dom/client'
import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'
import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'
import 'd3-flame-graph/dist/d3-flamegraph.css'
import './css/global.css'
import App from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'
import React from 'react'

// import reportWebVitals from './reportWebVitals'

const root = createRoot(document.getElementById('root')!)
const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                preventDuplicate={true}
                disableWindowBlurListener={true}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <App />
            </SnackbarProvider>
        </QueryClientProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)

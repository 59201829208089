import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MainCard from '@/components/MainCard'
import ComponentSkeleton from './ComponentSkeleton'

function ShadowBox({ shadow }: { shadow: string }) {
    return (
        <MainCard>
            <Stack spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="h6">boxShadow</Typography>
                <Typography variant="subtitle1">{shadow}</Typography>
            </Stack>
        </MainCard>
    )
}

function CustomShadowBox({ label, color, bgcolor }: { label: string; color: string; bgcolor?: string }) {
    return (
        <MainCard sx={{ bgcolor: bgcolor || 'inherit' }}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="subtitle1" color={color}>
                    {label}
                </Typography>
            </Stack>
        </MainCard>
    )
}

export default function ComponentShadow() {
    const theme = useTheme()

    return (
        <ComponentSkeleton>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MainCard title="Basic Shadow">
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="0" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="1" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="2" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="3" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="4" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="5" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="6" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="7" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="8" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="9" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="10" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="11" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="12" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="13" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="14" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="15" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="16" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="17" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="18" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="19" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="20" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="21" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="22" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="23" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <ShadowBox shadow="24" />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                <Grid item xs={12}>
                    <MainCard title="Custom Shadow">
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox label="z1" color="inherit" />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                <Grid item xs={12}>
                    <MainCard title="Color Shadow">
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox
                                    color={theme.palette.primary.contrastText}
                                    bgcolor={theme.palette.primary.main}
                                    label="primary"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox
                                    color={theme.palette.secondary.contrastText}
                                    bgcolor={theme.palette.secondary.main}
                                    label="secondary"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox
                                    color={theme.palette.success.contrastText}
                                    bgcolor={theme.palette.success.main}
                                    label="success"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox
                                    color={theme.palette.warning.contrastText}
                                    bgcolor={theme.palette.warning.main}
                                    label="warning"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox
                                    color={theme.palette.info.contrastText}
                                    bgcolor={theme.palette.info.main}
                                    label="info"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox
                                    color={theme.palette.error.contrastText}
                                    bgcolor={theme.palette.error.main}
                                    label="error"
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox color={theme.palette.primary.main} label="primary" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox color={theme.palette.secondary.main} label="secondary" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox color={theme.palette.success.main} label="success" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox color={theme.palette.warning.main} label="warning" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox color={theme.palette.info.main} label="info" />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <CustomShadowBox color={theme.palette.error.main} label="error" />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </ComponentSkeleton>
    )
}

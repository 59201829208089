import Typography from '@mui/material/Typography'
import MainCard from '@/components/MainCard'
import { useParams } from 'react-router'
import { ProjectHooks } from '@/api/backend.query.ts'
import { useAppStore } from '@/stores/AppStore.tsx'
import ActionsTable from '@/pages/projects/ActionsTable.tsx'
import { Box, Grid2, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React, { useEffect } from 'react'
import TracesTable from '@/pages/projects/TracesTable.tsx'

export default function ProjectSinglePage() {
    const params = useParams()
    const { projects } = useAppStore()
    const projectId = params.projectId!
    const project = projects?.find((project) => project.id === projectId)

    type TabName = 'actions' | 'traces' | 'users'
    const [tabSelected, setTabSelected] = React.useState<TabName>('actions')

    const {
        data: actionsListResponse,
        isFetched: actionsFetched,
        isStale: actionsStale,
        refetch: refetchActions,
    } = ProjectHooks.getActions(
        { projectId, page: 1, perPage: 15 },
        {
            enabled: false,
        }
    )

    const {
        data: tracesListResponse,
        isFetched: tracesFetched,
        isStale: tracesStale,
        refetch: refetchTraces,
    } = ProjectHooks.getTracesForProject(
        { projectId, page: 1, perPage: 15 },
        {
            enabled: false,
        }
    )

    function loadTab(tab: TabName) {
        switch (tab) {
            case 'actions':
                if (!actionsFetched || actionsStale) {
                    refetchActions()
                }
                setTabSelected(tab)
                break
            case 'traces':
                if (!tracesFetched || tracesStale) {
                    refetchTraces()
                }
                setTabSelected(tab)
                break
            case 'users':
                setTabSelected(tab)
                break
            default:
                throw new Error('Invalid tab selected: ' + tab)
        }
    }

    function handleTabChange(_event: React.SyntheticEvent, newValue: string) {
        loadTab(newValue as TabName)
    }

    useEffect(() => {
        loadTab('actions')
    }, [])

    if (!project) {
        return <></>
    }
    return (
        <Grid2 container>
            <Grid2 size={12}>
                <MainCard title={project?.name}>
                    <Typography variant="body2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero nec purus
                    </Typography>
                </MainCard>
            </Grid2>
            <Grid2 size={12}>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <TabContext value={tabSelected}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label="Actions" value="actions" />
                                <Tab label="Traces" value="traces" />
                                <Tab label="Users" value="users" />
                            </TabList>
                        </Box>
                        <TabPanel sx={{p:0}} value="actions">
                            {!actionsFetched && <Typography>Loading...</Typography>}
                            {actionsFetched && actionsListResponse && (
                                <ActionsTable project={project} actionData={actionsListResponse} />
                            )}
                        </TabPanel>
                        <TabPanel sx={{p:0}} value="traces">
                            {!tracesFetched && <Typography>Loading...</Typography>}
                            {tracesFetched && tracesListResponse && (
                                <TracesTable project={project} traceData={tracesListResponse} />
                            )}
                        </TabPanel>
                        <TabPanel sx={{p:0}} value="users">Item Three</TabPanel>
                    </TabContext>
                </MainCard>
            </Grid2>
        </Grid2>
    )
}

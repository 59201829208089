/**
 * Each node can have:
 *   - `k`: number[] => an array of glossary token IDs for ONE segment
 *   - `c`: child TrieNode[] => array of children
 *   - `v`: unknown => optional “value” if this node terminates a path
 */
interface TrieNode {
    k?: number[] // This node's segment in glossary IDs
    c?: TrieNode[] // Children
    v?: unknown // If set, the final data for that path
}

/**
 * Decode the TrieNode back to an object { originalKey => value }
 * @param node Current trie node
 * @param glossary Array of strings
 * @param path So far, an array of segments, each is number[]
 */
export function decodeTrie(node: TrieNode, glossary: string[], path: number[][] = []): Record<string, unknown> {
    const result: Record<string, unknown> = {}

    // If there's a value, that means path so far is a full key
    if (node.v !== undefined) {
        // Reconstruct original key from `path`.
        // Each element of `path` is an array of glossary IDs => convert to strings + join "::"
        const segmentStrings = path.map((segArr) => segArr.map((id) => glossary[id]).join('::'))
        const originalKey = segmentStrings.join('~')
        result[originalKey] = node.v
    }

    // Recurse into children
    if (node.c) {
        for (const child of node.c) {
            const childSegment = child.k ?? []
            // Extend path with the child's segment
            const newPath = [...path, childSegment]
            const childResult = decodeTrie(child, glossary, newPath)
            Object.assign(result, childResult)
        }
    }

    return result
}

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { ActionListResponse, Project } from '@/api/backend.types.ts'
import { Link } from 'react-router'
import { TablePagination } from '@mui/material'

interface HeadCell {
    id: string
    align: 'left' | 'right' | 'center' | 'inherit' | 'justify'
    disablePadding: boolean
    label: string
}

const headCells: HeadCell[] = [
    { id: 'tracking_no', align: 'left', disablePadding: false, label: 'Actions' },
    { id: 'trace_count', align: 'left', disablePadding: true, label: 'Trace Count' },
    { id: 'max_duration', align: 'left', disablePadding: false, label: 'Max Duration' },
    { id: 'avg_duration', align: 'left', disablePadding: false, label: 'Avg Duration' },
    { id: 'min_duration', align: 'left', disablePadding: false, label: 'Min Duration' },
    { id: 'last_seen', align: 'right', disablePadding: false, label: 'Last traced at' },
]

export default function ActionsTable({
    project,
    actionData,
}: {
    project: Project
    actionData: ActionListResponse | null
}) {

    const formatter = Intl.NumberFormat()
    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' },
                }}
            >
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.align}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {actionData?.data?.map((action, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    tabIndex={-1}
                                    key={action.action_uuid}
                                >
                                    <TableCell component="th" id={labelId} scope="row">
                                        <Link to={`/project/${project.id}/action/${action.action_uuid}`}>{action.action}</Link>
                                    </TableCell>
                                    <TableCell>{formatter.format(action.count)}</TableCell>
                                    <TableCell>{formatter.format(action.max_duration)}</TableCell>
                                    <TableCell>{formatter.format(action.avg_duration)}</TableCell>
                                    <TableCell>{formatter.format(action.min_duration)}</TableCell>
                                    <TableCell align="right">{action.last_seen_at}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {actionData && (
                <TablePagination
                    component="div"
                    count={actionData?.meta.pagination.count}
                    page={actionData?.meta.pagination.current_page}
                    onPageChange={() => {}}
                    rowsPerPage={10}
                    onRowsPerPageChange={() => {}}
                />
            )}

        </Box>
    )
}

import { useEffect, useRef, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Profile from './Profile'
import Transitions from '@/components/@extended/Transitions'
import MoreVertIcon from '@mui/icons-material/MoreVert'

export default function MobileSection() {
    const [open, setOpen] = useState<boolean>(false)
    const anchorRef = useRef<HTMLButtonElement | null>(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current?.contains(event.target as Node)) {
            return
        }
        setOpen(false)
    }

    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current && !open && anchorRef.current) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    const iconBackColorOpen = 'grey.300'
    const iconBackColor = 'grey.100'

    return (
        <>
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <IconButton
                    sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                    aria-label="open more menu"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="secondary"
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                sx={{ width: '100%' }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions direction="down" type="fade" position="top" in={open} {...TransitionProps}>
                        <Paper sx={{ boxShadow: 1 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <AppBar color="inherit">
                                    <Toolbar>
                                        {/*<Search />*/}
                                        <Profile />
                                    </Toolbar>
                                </AppBar>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    )
}

import { MouseEvent, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import Link from '@mui/material/Link'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { strengthColor, StrengthColorPair, strengthIndicator } from '@/utils/password-strength'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export default function AuthRegister() {
    const [strength, setStrength] = useState<number>(-1)
    const [level, setLevel] = useState<StrengthColorPair>({ label: 'Poor', color: 'error.main' })
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const changePassword = (value: string) => {
        const temp = strengthIndicator(value)
        setStrength(temp)
        setLevel(strengthColor(temp))
    }

    useEffect(() => {
        changePassword('')
    }, [])

    return (
        <>
            <form>
                <Grid container spacing={3}>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="firstname-signup">First Name*</InputLabel>
                            <OutlinedInput
                                id="firstname-login"
                                type="firstname"
                                value=""
                                name="firstname"
                                placeholder="John"
                                fullWidth
                            />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="lastname-signup">Last Name*</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="lastname-signup"
                                type="lastname"
                                value=""
                                name="lastname"
                                placeholder="Doe"
                                inputProps={{}}
                            />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="company-signup">Company</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="company-signup"
                                value=""
                                name="company"
                                placeholder="Demo Inc."
                                inputProps={{}}
                            />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="email-login"
                                type="email"
                                value=""
                                name="email"
                                placeholder="demo@company.com"
                                inputProps={{}}
                            />
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="password-signup">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="password-signup"
                                type={showPassword ? 'text' : 'password'}
                                value=""
                                name="password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            color="secondary"
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="******"
                                inputProps={{}}
                            />
                        </Stack>
                        {strength > -1 && (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid size={{ xs: 12 }}>
                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                            Password strength - {level?.label}
                                        </Typography>
                                        <Box
                                            sx={{
                                                bgcolor: level?.color,
                                                width: strength * 20 + '%',
                                                height: 8,
                                                borderRadius: '7px',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Typography variant="body2">
                            By Signing up, you agree to our &nbsp;
                            <Link variant="subtitle2" component={RouterLink} to="#">
                                Terms of Service
                            </Link>
                            &nbsp; and &nbsp;
                            <Link variant="subtitle2" component={RouterLink} to="#">
                                Privacy Policy
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button
                            disableElevation
                            disabled={false}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Create Account
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

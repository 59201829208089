import Box from '@mui/material/Box'
import React from 'react'

export default function SimpleBarScroll({
    children,
    sx,
    ...other
}: {
    children: React.ReactNode
    sx?: object
    other?: object
}) {
    return (
        <>
            <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
                {children}
            </Box>
        </>
    )
}

import Badge from './Badge'
import Button from './Button'
import CardContent from './CardContent'
import Checkbox from './Checkbox'
import Chip from './Chip'
import IconButton from './IconButton'
import InputLabel from './InputLabel'
import LinearProgress from './LinearProgress'
import Link from './Link'
import ListItemIcon from './ListItemIcon'
import OutlinedInput from './OutlinedInput'
import TableCell from './TableCell'
import Tabs from './Tabs'
import Typography from './Typography'
import { Theme } from '@mui/material'
import Avatar from '@/themes/overrides/Avatar.ts'
import { Components } from '@mui/material/styles/components'
import { BaseTheme } from '@mui/material/styles/createThemeNoVars'

export default function ComponentsOverrides(theme: Theme) {
    return {
        ...Button(theme),
        ...Badge(theme),
        ...CardContent(),
        ...Checkbox(theme),
        ...Chip(theme),
        ...IconButton(theme),
        ...InputLabel(theme),
        ...LinearProgress(),
        ...Link(),
        ...ListItemIcon(),
        ...OutlinedInput(theme),
        ...TableCell(theme),
        ...Tabs(),
        ...Typography(),
        ...Avatar(),
    } as Components<BaseTheme>
}

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import MainCard from '@/components/MainCard'
import { Grid2, Skeleton } from '@mui/material'

interface AnalyticEcommerceProps {
    title: string
    count: string | number | null
}

export default function AnalyticEcommerce({ title, count }: AnalyticEcommerceProps) {
    return (
        <MainCard contentSX={{ p: 2.25 }}>
            <Stack spacing={0.5}>
                <Typography variant="h6" color="text.secondary">
                    {title}
                </Typography>
                <Grid2 container alignItems="center">
                    <Grid2 size={12}>
                        {count !== null ? (
                            <Typography variant="h4" color="inherit">
                                {count}
                            </Typography>
                        ) : (
                            <Box width="100%">
                                <Skeleton animation="pulse" height={28} />
                            </Box>
                        )}
                    </Grid2>
                </Grid2>
            </Stack>
        </MainCard>
    )
}

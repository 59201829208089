import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'
import GitHubIcon from '@mui/icons-material/GitHub'
import GoogleIcon from '@mui/icons-material/Google'

export default function SocialAuth() {
    const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const googleHandler = async () => {
        // login || singup
    }

    const githubHandler = async () => {
        // login || singup
    }

    return (
        <Stack
            direction="row"
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'space-around', sm: 'space-between' }}
            sx={{ '& .MuiButton-startIcon': { mr: { xs: 0, sm: 1 }, ml: { xs: 0, sm: -0.5 } } }}
        >
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={true}
                startIcon={<GitHubIcon sx={{ mr: downSM ? 0.5 : 0 }} />}
                onClick={githubHandler}
            >
                Github
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                fullWidth={true}
                startIcon={<GoogleIcon sx={{ mr: downSM ? 0.5 : 0 }} />}
                onClick={googleHandler}
            >
                Google
            </Button>
        </Stack>
    )
}

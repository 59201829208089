import React from 'react'
import { Navigate, useLocation } from 'react-router'
import { useAuthStore } from '@/stores/AuthStore'
import ErrorService from '@/services/ErrorService.ts'

interface ProtectedRouteProps {
    children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { isAuthenticated, wasAuthenticated } = useAuthStore()
    const location = useLocation()

    // Check if we are not logged in
    if (!isAuthenticated()) {
        // If we had been logged in, but are now logged out, show a warning message
        if (wasAuthenticated()) {
            localStorage.clear()
            sessionStorage.clear()
            ErrorService.notifyWarning('Your session has expired. Please log in again.')
        }

        // Redirect to login page
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    // If we are logged in, show the protected route
    return <>{children}</>
}

export default ProtectedRoute

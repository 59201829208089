import { useEffect } from 'react';
import { AuthApi } from '@/api/backend.client';
import { useAuthStore } from '@/stores/AuthStore.tsx'
import { useNavigate } from 'react-router'

function isWindowVisible(): boolean {
    return document.visibilityState === 'visible'
}

export function AuthRefreshProvider() {
    const { auth, setAuth, logout, isAuthenticated, isNearExpiration } = useAuthStore()
    const navigate = useNavigate();

    useEffect(() => {
        let intervalId: NodeJS.Timeout
        const startRefreshInterval = () => {
            intervalId = setInterval(async () => {
                if (isWindowVisible() && isAuthenticated() && isNearExpiration()) {
                    try {
                        const response = await AuthApi.refresh()
                        setAuth(response.data) // Update the auth state with the refreshed token
                    } catch (error) {
                        console.error('Failed to refresh token:', error)
                        clearInterval(intervalId) // Stop the interval if the refresh fails
                        await logout()
                    }
                }

                // Redirect to login page if the user is not authenticated
                if (!isAuthenticated()) {
                    navigate('/login')
                }
            }, 10000)
        }

        // Start the interval only when the component mounts
        startRefreshInterval()

        // Cleanup on component unmount
        return () => {
            clearInterval(intervalId)
        }
    }, [auth, isAuthenticated, isNearExpiration, setAuth])

    return null // This component renders nothing, it's just for background logic
}

import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import MainCard from '@/components/MainCard'
import LeaderBoardStat from '@/components/cards/statistics/AnalyticEcommerce'
import { useAppStore } from '@/stores/AppStore.tsx'
import ProjectTable from './ProjectTable'
import { useEffect } from 'react'
import { OrganisationHooks } from '@/api/backend.query.ts'

export default function DashboardDefault() {
    // Get the active organisation and project from the store
    const { activeOrganisation, projects, organisationStats, setOrganisationStats } = useAppStore()

    // Fetch organisation stats
    const { data: organisationStatsResponse } = OrganisationHooks.getOrganisationStats(activeOrganisation?.id ?? '', {
        enabled: !!activeOrganisation, // Only run the query if activeOrganisation is valid
        refetchInterval: false, // Refetch every 60 seconds
    })

    // Update the organisation stats when the response is received
    useEffect(() => {
        if (organisationStatsResponse) {
            setOrganisationStats(organisationStatsResponse.data)
        }
    }, [organisationStatsResponse])

    return (
        <Grid container>
            {/* Display organisation stats */}
            <Grid container columnSpacing={2.75} rowSpacing={1.25} size={12} marginBottom={4.5}>
                <Grid size={{ xs: 12 }}>
                    <Typography variant="h5">{activeOrganisation?.name}</Typography>
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
                    <LeaderBoardStat
                        title="Trace Count"
                        count={organisationStats?.request_count.toLocaleString() ?? null}
                    />
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
                    <LeaderBoardStat
                        title="Actions Traced"
                        count={organisationStats?.action_count.toLocaleString() ?? null}
                    />
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
                    <LeaderBoardStat
                        title="Avg Duration"
                        count={organisationStats?.avg_duration.toLocaleString() ?? null}
                    />
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
                    <LeaderBoardStat
                        title="Unique Users"
                        count={organisationStats?.unique_users.toLocaleString() ?? null}
                    />
                </Grid>
            </Grid>

            {/* Display projects */}
            <Grid size={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid size={{ xs: 12 }}>
                        <Typography variant="h5">Projects</Typography>
                    </Grid>
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <ProjectTable
                        projects={
                            projects?.filter((p) => {
                                if (!activeOrganisation) return true
                                return p.organisation?.id === activeOrganisation?.id
                            }) ?? null
                        }
                    />
                </MainCard>
            </Grid>
        </Grid>
    )
}

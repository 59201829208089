import { createTheme } from '@mui/material/styles'
import { ColorBlindMode, daltonize } from '@/themes/daltonize.ts'
import { darken } from '@mui/material/styles'

export default function Palette(mode: 'light' | 'dark', colorBlindMode: ColorBlindMode) {
    const colors = {
        common: {
            black: '#000000',
            white: '#ffffff',
        },
        red: [
            daltonize('#fff1f0', colorBlindMode),
            daltonize('#ffccc7', colorBlindMode),
            daltonize('#ffa39e', colorBlindMode),
            daltonize('#ff7875', colorBlindMode),
            daltonize('#ff4d4f', colorBlindMode),
            daltonize('#f5222d', colorBlindMode),
            daltonize('#cf1322', colorBlindMode),
            daltonize('#a8071a', colorBlindMode),
            daltonize('#820014', colorBlindMode),
            daltonize('#5c0011', colorBlindMode),
        ],
        volcano: [
            daltonize('#fff2e8', colorBlindMode),
            daltonize('#ffd8bf', colorBlindMode),
            daltonize('#ffbb96', colorBlindMode),
            daltonize('#ff9c6e', colorBlindMode),
            daltonize('#ff7a45', colorBlindMode),
            daltonize('#fa541c', colorBlindMode),
            daltonize('#d4380d', colorBlindMode),
            daltonize('#ad2102', colorBlindMode),
            daltonize('#871400', colorBlindMode),
            daltonize('#610b00', colorBlindMode),
        ],
        orange: [
            daltonize('#fff7e6', colorBlindMode),
            daltonize('#ffe7ba', colorBlindMode),
            daltonize('#ffd591', colorBlindMode),
            daltonize('#ffc069', colorBlindMode),
            daltonize('#ffa940', colorBlindMode),
            daltonize('#fa8c16', colorBlindMode),
            daltonize('#d46b08', colorBlindMode),
            daltonize('#ad4e00', colorBlindMode),
            daltonize('#873800', colorBlindMode),
            daltonize('#612500', colorBlindMode),
        ],
        gold: [
            daltonize('#fffbe6', colorBlindMode),
            daltonize('#fff1b8', colorBlindMode),
            daltonize('#ffe58f', colorBlindMode),
            daltonize('#ffd666', colorBlindMode),
            daltonize('#ffc53d', colorBlindMode),
            daltonize('#faad14', colorBlindMode),
            daltonize('#d48806', colorBlindMode),
            daltonize('#ad6800', colorBlindMode),
            daltonize('#874d00', colorBlindMode),
            daltonize('#613400', colorBlindMode),
        ],
        yellow: [
            daltonize('#feffe6', colorBlindMode),
            daltonize('#ffffb8', colorBlindMode),
            daltonize('#fffb8f', colorBlindMode),
            daltonize('#fff566', colorBlindMode),
            daltonize('#ffec3d', colorBlindMode),
            daltonize('#fadb14', colorBlindMode),
            daltonize('#d4b106', colorBlindMode),
            daltonize('#ad8b00', colorBlindMode),
            daltonize('#876800', colorBlindMode),
            daltonize('#614700', colorBlindMode),
        ],
        lime: [
            daltonize('#fcffe6', colorBlindMode),
            daltonize('#f4ffb8', colorBlindMode),
            daltonize('#eaff8f', colorBlindMode),
            daltonize('#d3f261', colorBlindMode),
            daltonize('#bae637', colorBlindMode),
            daltonize('#a0d911', colorBlindMode),
            daltonize('#7cb305', colorBlindMode),
            daltonize('#5b8c00', colorBlindMode),
            daltonize('#3f6600', colorBlindMode),
            daltonize('#254000', colorBlindMode),
        ],
        green: [
            daltonize('#f6ffed', colorBlindMode),
            daltonize('#d9f7be', colorBlindMode),
            daltonize('#b7eb8f', colorBlindMode),
            daltonize('#95de64', colorBlindMode),
            daltonize('#73d13d', colorBlindMode),
            daltonize('#52c41a', colorBlindMode),
            daltonize('#389e0d', colorBlindMode),
            daltonize('#237804', colorBlindMode),
            daltonize('#135200', colorBlindMode),
            daltonize('#092b00', colorBlindMode),
        ],
        cyan: [
            daltonize('#e6fffb', colorBlindMode),
            daltonize('#b5f5ec', colorBlindMode),
            daltonize('#87e8de', colorBlindMode),
            daltonize('#5cdbd3', colorBlindMode),
            daltonize('#36cfc9', colorBlindMode),
            daltonize('#13c2c2', colorBlindMode),
            daltonize('#08979c', colorBlindMode),
            daltonize('#006d75', colorBlindMode),
            daltonize('#00474f', colorBlindMode),
            daltonize('#002329', colorBlindMode),
        ],
        blue: [
            daltonize('#e6f4ff', colorBlindMode),
            daltonize('#bae0ff', colorBlindMode),
            daltonize('#91caff', colorBlindMode),
            daltonize('#69b1ff', colorBlindMode),
            daltonize('#4096ff', colorBlindMode),
            daltonize('#1677ff', colorBlindMode),
            daltonize('#0958d9', colorBlindMode),
            daltonize('#003eb3', colorBlindMode),
            daltonize('#002c8c', colorBlindMode),
            daltonize('#001d66', colorBlindMode),
        ],
        purple: [
            daltonize('#f9f0ff', colorBlindMode),
            daltonize('#efdbff', colorBlindMode),
            daltonize('#d3adf7', colorBlindMode),
            daltonize('#b37feb', colorBlindMode),
            daltonize('#9254de', colorBlindMode),
            daltonize('#722ed1', colorBlindMode),
            daltonize('#531dab', colorBlindMode),
            daltonize('#391085', colorBlindMode),
            daltonize('#22075e', colorBlindMode),
            daltonize('#120338', colorBlindMode),
        ],
        magenta: [
            daltonize('#fff0f6', colorBlindMode),
            daltonize('#ffd6e7', colorBlindMode),
            daltonize('#ffadd2', colorBlindMode),
            daltonize('#ff85c0', colorBlindMode),
            daltonize('#f759ab', colorBlindMode),
            daltonize('#eb2f96', colorBlindMode),
            daltonize('#c41d7f', colorBlindMode),
            daltonize('#9e1068', colorBlindMode),
            daltonize('#780650', colorBlindMode),
            daltonize('#520339', colorBlindMode),
        ],
        grey: {
            0: '#ffffff',
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#f0f0f0',
            300: '#d9d9d9',
            400: '#bfbfbf',
            500: '#8c8c8c',
            600: '#595959',
            700: '#262626',
            800: '#141414',
            900: '#000000',
            A50: '#fafafb',
            A100: '#fafafa',
            A200: '#bfbfbf',
            A400: '#434343',
            A700: '#1f1f1f',
            A800: '#e6ebf1',
        },
    }

    // const paletteColor = ThemeOption(colors)

    switch (mode) {
        case 'light':
            return createTheme({
                palette: {
                    mode,
                    ...colors,
                    primary: {
                        light: colors.blue[1],
                        100: colors.blue[1],
                        200: colors.blue[2],
                        400: colors.blue[4],
                        500: colors.blue[5],
                        600: colors.blue[6],
                        main: colors.blue[6],
                        700: colors.blue[7],
                        800: colors.blue[8],
                        900: colors.blue[9],
                        dark: colors.blue[8],
                        contrastText: colors.common.white
                    },
                    secondary: {
                        light: colors.grey['100'],
                        100: colors.grey['100'],
                        200: colors.grey['200'],
                        400: colors.grey['400'],
                        500: colors.grey['500'],
                        600: colors.grey['600'],
                        main: colors.grey['600'],
                        700: colors.grey['700'],
                        800: colors.grey['800'],
                        900: colors.grey['900'],
                        A100: colors.grey['A100'],
                        A200: colors.grey['A200'],
                        dark: colors.grey['800'],
                        contrastText: colors.common.white
                    },
                    error: {
                        light: colors.red[3],
                        main: colors.red[5]!,
                        dark: colors.red[7],
                        contrastText: colors.common.white
                    },
                    warning: {
                        light: colors.gold[3],
                        main: colors.gold[5]!,
                        dark: colors.gold[7],
                        contrastText: colors.common.white
                    },
                    info: {
                        light: colors.cyan[3],
                        main: colors.cyan[5]!,
                        dark: colors.cyan[7],
                        contrastText: colors.common.white
                    },
                    success: {
                        light: colors.green[3],
                        main: colors.green[5]!,
                        dark: colors.green[7],
                        contrastText: colors.common.white
                    },
                    text: {
                        primary: colors.grey['700'],
                        secondary: colors.grey['500'],
                        disabled: colors.grey['400'],
                    },
                    action: {
                        disabled: colors.grey['300'],
                    },
                    divider: colors.grey['200'],
                    background: {
                        paper: colors.grey['0'],
                        default: colors.grey['200'],
                    },
                },
                colorBlindMode: 'none',
            })
        case 'dark':
            return createTheme({
                palette: {
                    mode,
                    ...colors,
                    primary: {
                        light: darken(colors.blue[1]!, 0.3),
                        100: darken(colors.blue[1]!, 0.3),
                        200: darken(colors.blue[2]!, 0.3),
                        400: darken(colors.blue[4]!, 0.3),
                        500: darken(colors.blue[5]!, 0.3),
                        600: darken(colors.blue[6]!, 0.3),
                        main: darken(colors.blue[6]!, 0.3),
                        700: darken(colors.blue[7]!, 0.3),
                        800: darken(colors.blue[8]!, 0.3),
                        900: darken(colors.blue[9]!, 0.3),
                        dark: darken(colors.blue[8]!, 0.3),
                        contrastText: colors.common.white
                    },
                    secondary: {
                        light: darken(colors.grey['100'], 0.3),
                        100: darken(colors.grey['100'], 0.3),
                        200: darken(colors.grey['200'], 0.3),
                        400: darken(colors.grey['400'], 0.3),
                        500: darken(colors.grey['500'], 0.3),
                        600: darken(colors.grey['600'], 0.3),
                        main: darken(colors.grey['600'], 0.3),
                        700: darken(colors.grey['700'], 0.3),
                        800: darken(colors.grey['800'], 0.3),
                        900: darken(colors.grey['900'], 0.3),
                        A100: darken(colors.grey['A100'], 0.3),
                        A200: darken(colors.grey['A200'], 0.3),
                        dark: darken(colors.grey['800'], 0.3),
                        contrastText: colors.common.white
                    },
                    error: {
                        light: darken(colors.red[3]!, 0.3),
                        main: darken(colors.red[5]!, 0.3),
                        dark: darken(colors.red[7]!, 0.3),
                        contrastText: colors.common.white
                    },
                    warning: {
                        light: darken(colors.gold[3]!, 0.3),
                        main: darken(colors.gold[5]!, 0.3),
                        dark: darken(colors.gold[7]!, 0.3),
                        contrastText: colors.common.white
                    },
                    info: {
                        light: darken(colors.cyan[3]!, 0.3),
                        main: darken(colors.cyan[5]!, 0.3),
                        dark: darken(colors.cyan[7]!, 0.3),
                        contrastText: colors.common.white
                    },
                    success: {
                        light: darken(colors.green[3]!, 0.3),
                        main: darken(colors.green[5]!, 0.3),
                        dark: darken(colors.green[7]!, 0.3),
                        contrastText: colors.common.white
                    },
                    text: {
                        primary: colors.grey['400'],
                        secondary: colors.grey['500'],
                        disabled: colors.grey['400'],
                    },
                    action: {
                        disabled: colors.grey['300'],
                    },
                    divider: colors.grey['600'],
                    background: {
                        paper: colors.grey['800'],
                        default: colors.grey['800'],
                    },
                },
                colorBlindMode: 'none',
            })
        default:
            throw new Error(`Unknown theme mode: ${mode}`)
    }
}

import { BrowserRouter, Route, Routes } from 'react-router'
import ThemeCustomization from './themes/index'
import ScrollTop from './components/ScrollTop'
import { useLocaleEffect } from './services/LocaleService'
import { LocaleProvider } from './providers/LocaleProvider'
import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import ErrorService from '@/services/ErrorService.ts'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAppStore } from '@/stores/AppStore.tsx'
import DashboardLayout from '@/layout/Dashboard'
import DashboardDefault from '@/pages/dashboard'
import ComponentColor from '@/pages/component-overview/color.tsx'
import ComponentFlameGraph from '@/pages/component-overview/flamegraph.tsx'
import SamplePage from '@/pages/extra-pages/sample-page.tsx'
import ComponentShadow from '@/pages/component-overview/shadows.tsx'
import ComponentTypography from '@/pages/component-overview/typography.tsx'
import ProtectedRoute from '@/components/routing/ProtectedRoute.tsx'
import MinimalLayout from '@/layout/MinimalLayout'
import Login from '@/pages/authentication/login.tsx'
import Register from '@/pages/authentication/register.tsx'
import { ErrorBoundary } from 'react-error-boundary'
import { ExceptionPage } from '@/pages/error/ExceptionPage.tsx'
import { PerfbaseException } from '@/exceptions/PerfbaseException.ts'
import { OrganisationHooks, ProjectHooks } from '@/api/backend.query.ts'
import ProjectSinglePage from '@/pages/projects/ProjectSinglePage.tsx'
import { useAuthStore } from '@/stores/AuthStore.tsx'
import { AuthRefreshProvider } from '@/auth/AuthRefreshProvider.tsx'
import TraceSinglePage from '@/pages/traces/TraceSinglePage.tsx'

export const queryClient = new QueryClient()

const App: React.FC = () => {
    const isLocaleLoaded = useLocaleEffect()

    const { colorBlindMode, setOrganisations, setProjects } = useAppStore()
    const { isAuthenticated } = useAuthStore()

    // Fetch organisations
    const { data: organisationResponse } = OrganisationHooks.getOrganisations(
        { page: 1, perPage: 10000000 },
        {
            staleTime: 5000,
            enabled: isAuthenticated(),
        }
    )
    useEffect(() => setOrganisations(organisationResponse?.data ?? null), [organisationResponse])

    // Fetch projects
    const { data: projectResponse } = ProjectHooks.getProjects(
        { page: 1, perPage: 10000000 },
        {
            staleTime: 5000,
            enabled: isAuthenticated(),
        }
    )
    useEffect(() => setProjects(projectResponse?.data ?? null), [projectResponse])

    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        ErrorService.setEnqueueSnackbar(enqueueSnackbar)
    }, [enqueueSnackbar])

    if (!isLocaleLoaded) {
        return <div></div>
    }

    const lightMode = !(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light'

    return (
        <LocaleProvider>
            <ThemeCustomization lightMode={lightMode} colorBlindMode={colorBlindMode}>
                <ScrollTop>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <ErrorBoundary FallbackComponent={ExceptionPage}>
                                            <ProtectedRoute>
                                                <AuthRefreshProvider />
                                                <DashboardLayout />
                                            </ProtectedRoute>
                                        </ErrorBoundary>
                                    }
                                >
                                    <Route index element={<DashboardDefault />} />
                                    <Route path={'project/:projectId'} element={<ProjectSinglePage />} />
                                    <Route path={'project/:projectId/action/:actionId'} element={<ProjectSinglePage />} />
                                    <Route path={'project/:projectId/trace/:traceId'} element={<TraceSinglePage />} />
                                    <Route path={'color'} element={<ComponentColor />} />
                                    <Route path={'flamegraph'} element={<ComponentFlameGraph />} />
                                    <Route path={'sample-page'} element={<SamplePage />} />
                                    <Route path={'shadow'} element={<ComponentShadow />} />
                                    <Route path={'typography'} element={<ComponentTypography />} />
                                </Route>

                                <Route path="/" element={<MinimalLayout />}>
                                    <Route path="login" element={<Login />} />
                                    <Route path="register" element={<Register />} />
                                </Route>

                                <Route
                                    path="*"
                                    element={
                                        <ExceptionPage
                                            error={
                                                new PerfbaseException(
                                                    404,
                                                    'Not Found',
                                                    'The requested URL was not found on this platform.'
                                                )
                                            }
                                        />
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                    </QueryClientProvider>
                </ScrollTop>
            </ThemeCustomization>
        </LocaleProvider>
    )
}

export default App

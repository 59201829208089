import { Link } from 'react-router'
import Grid2 from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AuthWrapper from './AuthWrapper'
import AuthRegister from './auth-forms/AuthRegister'

export default function Register() {
    return (
        <AuthWrapper>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                        <Typography variant="h3">Sign up</Typography>
                        <Typography
                            component={Link}
                            to="/login"
                            variant="body1"
                            sx={{ textDecoration: 'none' }}
                            color="primary"
                        >
                            Already have an account?
                        </Typography>
                    </Stack>
                </Grid2>
                <Grid2 size={12}>
                    <AuthRegister />
                </Grid2>
            </Grid2>
        </AuthWrapper>
    )
}

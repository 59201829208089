import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import MainCard from '@/components/MainCard'
import ComponentSkeleton from './ComponentSkeleton'
import Grid2 from '@mui/material/Grid2'
import { useAppStore } from '@/stores/AppStore.tsx'
import { Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { ColorBlindModeOptions, DefaultColorBlindMode } from '@/themes/daltonize.ts'

function ColorBox({
    bgcolor,
    title,
    data,
    dark = false,
    main = false,
}: {
    bgcolor: string
    title: string
    data: { label: string; color: string }
    dark?: boolean
    main?: boolean
}) {
    return (
        <Card sx={{ '&.MuiPaper-root': { borderRadius: '0px' } }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 2.5,
                    bgcolor,
                    color: dark ? 'grey.800' : '#ffffff',
                    border: main ? '1px dashed' : '1px solid transparent',
                }}
            >
                {title && (
                    <Grid2 container justifyContent="space-around" alignItems="center">
                        <Grid2>
                            {data && (
                                <Stack spacing={0.75} alignItems="center">
                                    <Typography variant="subtitle2">{data.label}</Typography>
                                    <Typography variant="subtitle1">{data.color}</Typography>
                                </Stack>
                            )}
                        </Grid2>
                        <Grid2>
                            <Typography variant="subtitle1" color="inherit">
                                {title}
                            </Typography>
                        </Grid2>
                    </Grid2>
                )}
            </Box>
        </Card>
    )
}

export default function ComponentColor() {
    const appStore = useAppStore()

    return (
        <ComponentSkeleton>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <Select
                        variant={'standard'}
                        inputProps={{ 'aria-label': 'Without label' }}
                        autoWidth={true}
                        value={appStore.colorBlindMode}
                        onChange={(e) => {
                            switch (e.target.value) {
                                case 'none':
                                case 'protanopia':
                                case 'deuteranopia':
                                case 'tritanopia':
                                case 'monochromatic':
                                    appStore.setColorBlindMode(e.target.value)
                                    break
                                default:
                                    appStore.setColorBlindMode(DefaultColorBlindMode)
                                    break
                            }
                        }}
                    >
                        {ColorBlindModeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 container spacing={3}>
                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                    <MainCard title="Success Color">
                        <Stack>
                            <ColorBox
                                bgcolor="success.light"
                                data={{ label: 'Green-4', color: '#95de64' }}
                                title="success.light"
                                dark
                            />
                            <ColorBox
                                bgcolor="success.main"
                                data={{ label: 'Green-6', color: '#52c41a' }}
                                title="success.main"
                                main
                            />
                            <ColorBox
                                bgcolor="success.dark"
                                data={{ label: 'Green-8', color: '#237804' }}
                                title="success.dark"
                            />
                        </Stack>
                    </MainCard>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                    <MainCard title="Error Color">
                        <Stack>
                            <ColorBox
                                bgcolor="error.light"
                                data={{ label: 'Red-4', color: '#ff7875' }}
                                title="error.light"
                                dark
                            />
                            <ColorBox
                                bgcolor="error.main"
                                data={{ label: 'Red-6', color: '#f5222d' }}
                                title="error.main"
                                main
                            />
                            <ColorBox
                                bgcolor="error.dark"
                                data={{ label: 'Red-8', color: '#a8071a' }}
                                title="error.dark"
                            />
                        </Stack>
                    </MainCard>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                    <MainCard title="Warning Color">
                        <Stack>
                            <ColorBox
                                bgcolor="warning.light"
                                data={{ label: 'Gold-4', color: '#ffd666' }}
                                title="warning.light"
                                dark
                            />
                            <ColorBox
                                bgcolor="warning.main"
                                data={{ label: 'Gold-6', color: '#faad14' }}
                                title="warning.main"
                                main
                            />
                            <ColorBox
                                bgcolor="warning.dark"
                                data={{ label: 'Gold-8', color: '#ad6800' }}
                                title="warning.dark"
                            />
                        </Stack>
                    </MainCard>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                    <MainCard title="Primary Color">
                        <Stack>
                            <ColorBox
                                bgcolor="primary.100"
                                data={{ label: 'Blue-2', color: '#bae7ff' }}
                                title="primary[100]"
                                dark
                            />
                            <ColorBox
                                bgcolor="primary.200"
                                data={{ label: 'Blue-3', color: '#91d5ff' }}
                                title="primary[200]"
                                dark
                            />
                            <ColorBox
                                bgcolor="primary.light"
                                data={{ label: 'Blue-4', color: '#69c0ff' }}
                                title="primary.light"
                                dark
                            />
                            <ColorBox
                                bgcolor="primary.400"
                                data={{ label: 'Blue-5', color: '#40a9ff' }}
                                title="primary[400]"
                            />
                            <ColorBox
                                bgcolor="primary.main"
                                data={{ label: 'Blue-6', color: '#1890ff' }}
                                title="primary.main"
                                main
                            />
                            <ColorBox
                                bgcolor="primary.dark"
                                data={{ label: 'Blue-7', color: '#096dd9' }}
                                title="primary.dark"
                            />
                            <ColorBox
                                bgcolor="primary.700"
                                data={{ label: 'Blue-8', color: '#0050b3' }}
                                title="primary[700]"
                            />
                            <ColorBox
                                bgcolor="primary.900"
                                data={{ label: 'Blue-10', color: '#002766' }}
                                title="primary.900"
                            />
                        </Stack>
                    </MainCard>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                    <MainCard title="Secondary Color">
                        <Stack>
                            <ColorBox
                                bgcolor="secondary.100"
                                data={{ label: 'Grey-2', color: '#f5f5f5' }}
                                title="secondary[100]"
                                dark
                            />
                            <ColorBox
                                bgcolor="secondary.200"
                                data={{ label: 'Grey-3', color: '#f0f0f0' }}
                                title="secondary[200]"
                                dark
                            />
                            <ColorBox
                                bgcolor="secondary.light"
                                data={{ label: 'Grey-4', color: '#d9d9d9' }}
                                title="secondary.light"
                                dark
                            />
                            <ColorBox
                                bgcolor="secondary.400"
                                data={{ label: 'Grey-5', color: '#bfbfbf' }}
                                title="secondary[400]"
                                dark
                            />
                            <ColorBox
                                bgcolor="secondary.main"
                                data={{ label: 'Grey-6', color: '#8c8c8c' }}
                                title="secondary.main"
                                main
                            />
                            <ColorBox
                                bgcolor="secondary.600"
                                data={{ label: 'Grey-7', color: '#595959' }}
                                title="secondary.600"
                            />
                            <ColorBox
                                bgcolor="secondary.dark"
                                data={{ label: 'Grey-8', color: '#262626' }}
                                title="secondary.dark"
                            />
                            <ColorBox
                                bgcolor="secondary.800"
                                data={{ label: 'Grey-9', color: '#141414' }}
                                title="secondary[800]"
                            />
                        </Stack>
                    </MainCard>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                    <MainCard title="Other Color">
                        <Stack>
                            <ColorBox
                                bgcolor="secondary.A100"
                                data={{ label: 'Grey-A1', color: '#ffffff' }}
                                title="secondary.A100"
                                dark
                            />
                            <ColorBox
                                bgcolor="secondary.A200"
                                data={{ label: 'Grey-A2', color: '#434343' }}
                                title="secondary.A200"
                            />
                        </Stack>
                    </MainCard>
                </Grid2>
            </Grid2>
        </ComponentSkeleton>
    )
}

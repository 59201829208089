import { PerfbaseException } from '@/exceptions/PerfbaseException'
import { isRouteErrorResponse, useNavigate } from 'react-router'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Alert, AlertTitle } from '@mui/material'
import Typography from '@mui/material/Typography'
import MainCard from '@/components/MainCard.tsx'

interface Props {
    error: unknown
    resetErrorBoundary?: (...args: unknown[]) => void
}

export function ExceptionPage({ error, resetErrorBoundary }: Props) {
    const navigate = useNavigate()
    console.log(error)
    if (error instanceof PerfbaseException) {
        switch (error.statusCode) {
            case 401:
                // Handle 401 Unauthorized
                // Redirect the user to the login page
                navigate('/login')
                break
            default:
                break
        }
    }

    let status = 0
    let errorName = 'Error'
    let errorText = 'An unexpected error occurred.'

    if (error) {
        if (isRouteErrorResponse(error)) {
            status = error.status
            errorName = error.statusText
            errorText = error.data
        } else if (error instanceof PerfbaseException) {
            status = error.statusCode
            errorName = error.name
            errorText = error.message
        } else if (error instanceof Error) {
            errorName = error.name
            errorText = error.message
        }
    }

    return (
        <Container maxWidth="xl">
            <MainCard sx={{ py: 5, px: 4, mt: 10 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    <AlertTitle>{errorName}</AlertTitle>
                    {status > 0 ? `(${status})` : ''} {errorText}
                    <Stack direction="row" alignItems={'center'} spacing={2} sx={{ alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Try going back or contact support if it persists.
                        </Typography>
                    </Stack>
                </Alert>

                <Button
                    onClick={() => {
                        if (resetErrorBoundary) {
                            resetErrorBoundary()
                        }
                        return navigate(-1)
                    }}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                >
                    Back
                </Button>

                <Button onClick={() => navigate('/')} variant="contained" color="secondary" sx={{ mt: 3, ml: 2 }}>
                    Home
                </Button>
            </MainCard>
        </Container>
    )
}

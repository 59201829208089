import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { Project, TraceListResponse } from '@/api/backend.types.ts'
import { Link } from 'react-router'
import { TablePagination } from '@mui/material'

interface HeadCell {
    id: string
    align: 'left' | 'right' | 'center' | 'inherit' | 'justify'
    disablePadding: boolean
    label: string
}

const headCells: HeadCell[] = [
    { id: 'traces', align: 'left', disablePadding: false, label: 'Traces' },
    { id: 'status_code', align: 'left', disablePadding: false, label: 'Status Code' },
    { id: 'user_id', align: 'left', disablePadding: true, label: 'User ID' },
    { id: 'duration', align: 'left', disablePadding: false, label: 'Duration' },
    { id: 'peak_memory_usage', align: 'left', disablePadding: false, label: 'Peak Memory' },
    { id: 'traced_at', align: 'right', disablePadding: false, label: 'Traced At' },
]

export default function TracesTable({ project, traceData }: { project: Project; traceData: TraceListResponse | null }) {
    const formatter = Intl.NumberFormat()
    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' },
                }}
            >
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.align}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {traceData?.data?.map((trace, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    tabIndex={-1}
                                    key={trace.trace_uuid}
                                >
                                    <TableCell component="th" id={labelId} scope="row">
                                        <Link
                                            to={`/project/${project.id}/trace/${trace.trace_uuid}`}
                                        >
                                            {trace.action}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{formatter.format(trace.http_status_code)}</TableCell>
                                    <TableCell>{trace.user_id}</TableCell>
                                    <TableCell>{formatter.format(trace.duration)}</TableCell>
                                    <TableCell>
                                        {trace.memory_allocated_peak > 0 ? trace.memory_allocated_peak : 'N/A'}
                                    </TableCell>
                                    <TableCell align="right">{trace.created_at}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {traceData && (
                <TablePagination
                    component="div"
                    count={traceData?.meta.pagination.count}
                    page={traceData?.meta.pagination.current_page}
                    onPageChange={() => {}}
                    rowsPerPage={10}
                    onRowsPerPageChange={() => {}}
                />
            )}
        </Box>
    )
}

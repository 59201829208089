import { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import MainCard from '../../../../components/MainCard'
import Transitions from '../../../../components/@extended/Transitions'
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@mui/material/Button'

export default function Notification() {
    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'))

    const anchorRef = useRef(null)
    const [read, setRead] = useState(3)
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                color="secondary"
                sx={{ color: 'text.primary' }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge badgeContent={read} color="primary">
                    <NotificationsNoneOutlinedIcon />
                </Badge>
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                // disablePortal
                popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [matchesXs ? -5 : 0, 9] } }] }}
            >
                {({ TransitionProps }) => (
                    <Transitions
                        direction="down"
                        type="grow"
                        position={matchesXs ? 'top' : 'top-right'}
                        in={open}
                        {...TransitionProps}
                    >
                        <Paper
                            sx={{
                                boxShadow: 1,
                                minWidth: 285,
                                width: { xs: '100vw', sm: 420 },
                                // maxWidth: { xs: 285, md: 420 },
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title="Notifications"
                                    elevation={0}
                                    content={false}
                                    secondary={
                                        <>
                                            {read > 0 && (
                                                <Button
                                                    variant={'outlined'}
                                                    color={'secondary'}
                                                    size={'small'}
                                                    endIcon={<DoneAllIcon />}
                                                    onClick={() => setRead(0)}
                                                >
                                                    Mark all read
                                                </Button>
                                            )}
                                        </>
                                    }
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            p: 0,
                                            '& .MuiListItemButton-root': {
                                                py: 0.5,
                                            },
                                            '.MuiListItemIcon-root': {
                                                pl: 1,
                                            },
                                        }}
                                    >
                                        <ListItemButton disableRipple={true}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'success.main', bgcolor: 'transparent' }}>
                                                    <InsertEmoticonOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        It&apos;s{' '}
                                                        <Typography component="span" variant="subtitle1">
                                                            Cristina danny&apos;s
                                                        </Typography>{' '}
                                                        birthday today.
                                                    </Typography>
                                                }
                                                secondary="2 min ago"
                                            />
                                            <ListItemIcon>
                                                <IconButton size={'small'} aria-label="delete">
                                                    <ClearOutlinedIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton disableRipple={true}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'success.main', bgcolor: 'transparent' }}>
                                                    <InsertEmoticonOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        <Typography component="span" variant="subtitle1">
                                                            Aida Burg
                                                        </Typography>{' '}
                                                        commented your post.
                                                    </Typography>
                                                }
                                                secondary="5 August"
                                            />
                                            <ListItemIcon>
                                                <IconButton size={'small'} aria-label="delete">
                                                    <ClearOutlinedIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton disableRipple={true}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'success.main', bgcolor: 'transparent' }}>
                                                    <InsertEmoticonOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        Your Profile is Complete &nbsp;
                                                        <Typography component="span" variant="subtitle1">
                                                            60%
                                                        </Typography>{' '}
                                                    </Typography>
                                                }
                                                secondary="7 hours ago"
                                            />
                                            <ListItemIcon>
                                                <IconButton size={'small'} aria-label="delete">
                                                    <ClearOutlinedIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton disableRipple={true}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'success.main', bgcolor: 'transparent' }}>
                                                    <InsertEmoticonOutlinedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        <Typography component="span" variant="subtitle1">
                                                            Cristina Danny
                                                        </Typography>{' '}
                                                        invited to join{' '}
                                                        <Typography component="span" variant="subtitle1">
                                                            Meeting.
                                                        </Typography>
                                                    </Typography>
                                                }
                                                secondary="Daily scrum meeting time"
                                            />
                                            <ListItemIcon>
                                                <IconButton size={'small'} aria-label="delete">
                                                    <ClearOutlinedIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6" color="primary">
                                                        View All
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}

export default function Avatar() {
    return {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
        MuiGrid2: {
            styleOverrides: {
                root: {
                    maxWidth: '100vw',
                    overflow: 'hidden',
                },
            },
        },
    }
}

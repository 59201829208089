import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import NavItem from './NavItem'
import { useMenuStore } from '@/stores/MenuStore'
import { NavigationChild, NavigationItem } from '@/interfaces/GlobalInterfaces'

export default function NavGroup({ item }: { item: NavigationItem }) {
    const { isDashboardDrawerOpened: drawerOpen } = useMenuStore()

    const navCollapse = item.children.map((menuItem: NavigationChild) => {
        switch (menuItem.type) {
            case 'collapse':
                return (
                    <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
                        collapse - only available in paid version
                    </Typography>
                )
            case 'item':
                return <NavItem key={menuItem.id} item={menuItem} level={1} />
            default:
                return (
                    <Typography key={menuItem.id} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Items
                    </Typography>
                )
        }
    })

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {item.title}
                        </Typography>
                        {/* only available in paid version */}
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
            {navCollapse}
        </List>
    )
}

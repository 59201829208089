import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid2'
import Link from '@mui/material/Link'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as v from 'valibot'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorService from '@/services/ErrorService'
import { useAuthStore } from '@/stores/AuthStore.tsx'
import { AuthHooks } from '@/api/backend.query.ts'
import { isBooleanResponse, isUserAuthTokenResponse } from '@/api/backend.types'
import { match } from 'ts-pattern'
import SocialAuth from '@/pages/authentication/auth-forms/SocialAuth.tsx'

const LoginValidator = v.object({
    email: v.pipe(v.string(), v.email()),
    password: v.pipe(v.string(), v.minLength(1)),
})

const AuthLogin: React.FC = () => {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [errors, setErrors] = React.useState<{ email?: string; password?: string; submit?: string }>({})
    const [checked, setChecked] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)

    const navigate = useNavigate()
    const { setUser, setAuth } = useAuthStore()

    const { mutateAsync: login, isPending } = AuthHooks.login()

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrors({})
        try {
            v.parse(LoginValidator, { email, password })
            const response = await login({ email, password })
            return match(response)
                .when(isUserAuthTokenResponse, (response) => {
                    // Got a valid authentication response
                    if (response.data?.user) setUser(response.data.user)
                    if (response.data?.auth) setAuth(response.data.auth)
                    navigate('/')
                })
                .when(isBooleanResponse, () => {
                    // Probably failed
                    return false
                })
                .otherwise(() => {
                    return false
                })
        } catch (err: unknown) {
            if (v.isValiError(err)) {
                ErrorService.notifyWarning('Please check the login form for errors!', { variant: 'error' })
                const newErrors: { email?: string; password?: string } = {}
                for (const issue of err.issues) {
                    if (issue.kind !== 'validation') continue
                    const keys: string[] = issue.path?.map((p) => p.key as string) ?? []
                    if (keys.includes('email')) newErrors.email = 'Invalid email address'
                    if (keys.includes('password')) newErrors.password = 'Invalid password'
                }
                setErrors(newErrors)
            } else if (err instanceof Error) {
                ErrorService.notifyError(err.message)
                setErrors({ submit: err.message })
            } else {
                ErrorService.notifyWarning('An unknown error occurred.', { variant: 'error' })
                setErrors({ submit: 'An unknown error occurred.' })
            }
        }
    }

    return (
        <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="email-login">Email Address</InputLabel>
                        <OutlinedInput
                            id="email-login"
                            type="email"
                            value={email}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email address"
                            fullWidth
                            error={Boolean(errors.email)}
                        />
                    </Stack>
                    {errors.email && (
                        <FormHelperText error id="email-error">
                            {errors.email}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid size={12}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="password-login">Password</InputLabel>
                        <OutlinedInput
                            fullWidth
                            error={Boolean(errors.password)}
                            id="password-login"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        color="secondary"
                                    >
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder="Enter password"
                        />
                    </Stack>
                    {errors.password && (
                        <FormHelperText error id="password-error">
                            {errors.password}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid size={12} sx={{ mt: -1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setChecked(event.target.checked)
                                    }
                                    name="checked"
                                    color="primary"
                                    size="small"
                                />
                            }
                            label={<Typography variant="h6">Keep me signed in</Typography>}
                        />
                        <Link component={RouterLink} to="/forgot-password" variant="h6" color="text.primary">
                            Forgot Password?
                        </Link>
                    </Stack>
                </Grid>
                {errors.submit && (
                    <Grid size={12}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                )}
                <Grid size={12}>
                    <Button
                        disableElevation
                        disabled={isPending}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Login
                    </Button>
                </Grid>
                <Grid size={12}>
                    <Divider>
                        <Typography variant="caption">Login with</Typography>
                    </Divider>
                </Grid>
                <Grid size={12}>
                    <SocialAuth />
                </Grid>
            </Grid>
        </form>
    )
}

export default AuthLogin

import MainCard from '@/components/MainCard'
import ComponentSkeleton from './ComponentSkeleton'
import { PerfbaseFlameGraph } from '@/components/FlameGraph/PerfbaseFlameGraph'
import { memo, useEffect, useState } from 'react'
import Grid2 from '@mui/material/Grid2'
import { ProfilerData } from '@/utils/durationUtils.ts'
import { useErrorBoundary } from 'react-error-boundary'

const ComponentFlameGraph = memo(() => {
    const [data, setData] = useState<ProfilerData | null>(null)
    const { showBoundary } = useErrorBoundary()
    useEffect(() => {
        fetch('/data3.json')
            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData)
            })
            .catch((error) => {
                console.error('Error loading data:', error)
                showBoundary(error)
            })
    }, [])

    if (!data) return <>Loading...</>

    return (
        <ComponentSkeleton>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <MainCard title="Flame Graph">
                        <PerfbaseFlameGraph data={data} />
                    </MainCard>
                </Grid2>
            </Grid2>
        </ComponentSkeleton>
    )
})

export default ComponentFlameGraph

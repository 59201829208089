import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'
import { MenuItem, Select, Theme } from '@mui/material'
import Avatar from '@/components/@extended/Avatar.tsx'
import { useAppStore } from '@/stores/AppStore'

export default function HeaderContent() {
    const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const { activeOrganisation, setActiveOrganisation, organisations } = useAppStore()
    return (
        <>
            <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                <Select
                    displayEmpty
                    value={organisations ? (activeOrganisation?.id ?? '') : ''}
                    variant={'standard'}
                    inputProps={{ 'aria-label': 'Without label' }}
                    autoWidth={true}
                    fullWidth={downSM}
                    sx={{
                        border: 0,
                        '&:before': {
                            borderBottom: 0,
                        },
                    }}
                >
                    {!organisations && <MenuItem value="">Loading...</MenuItem>}
                    {organisations && <MenuItem value="">Select Organisation</MenuItem>}
                    {(organisations ?? []).map((organisation) => (
                        <MenuItem
                            key={organisation.id}
                            value={organisation.id}
                            onClick={() => setActiveOrganisation(organisation)}
                        >
                            <Avatar
                                size={'xs'}
                                sx={{
                                    display: 'inline-flex',
                                    mr: 0.5,
                                    fontSize: '0.75rem',
                                    fontWeight: 'bolder',
                                }}
                                initialCount={1}
                            >
                                {organisation.name}
                            </Avatar>
                            {organisation.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Notification />
            {!downSM && <Profile />}
            {downSM && <MobileSection />}
        </>
    )
}

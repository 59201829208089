import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Dot from '@/components/@extended/Dot'
import { Project } from '@/api/backend.types.ts'
import { Link } from 'react-router'

interface HeadCell {
    id: string
    align: 'left' | 'right' | 'center' | 'inherit' | 'justify'
    disablePadding: boolean
    label: string
}

const headCells: HeadCell[] = [
    { id: 'project', align: 'left', disablePadding: false, label: 'Project' },
    { id: 'subscription', align: 'left', disablePadding: false, label: 'Subscription' },
    { id: 'organisation', align: 'left', disablePadding: false, label: 'Organisation' },
    { id: 'region', align: 'left', disablePadding: false, label: 'Region' },
    { id: 'status', align: 'left', disablePadding: false, label: 'Status' },
]

interface OrderStatusProps {
    status: number
}

function OrderStatus({ status }: OrderStatusProps) {
    let color: string
    let title: string

    switch (status) {
        case 0:
            color = 'warning'
            title = 'Pending'
            break
        case 1:
            color = 'success'
            title = 'Live'
            break
        case 2:
            color = 'error'
            title = 'Rejected'
            break
        default:
            color = 'primary'
            title = 'None'
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    )
}

export default function ProjectTable({ projects }: { projects: Project[] | null }) {
    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' },
                }}
            >
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.align}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects?.map((project, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    tabIndex={-1}
                                    key={project.id}
                                >
                                    <TableCell component="th" id={labelId} scope="row">
                                        <Link to={`/project/${project.id}`}>{project.name}</Link>
                                    </TableCell>
                                    <TableCell>{project.subscription?.name}</TableCell>
                                    <TableCell>{project.organisation?.name}</TableCell>
                                    <TableCell>{project.region}</TableCell>
                                    <TableCell>
                                        <OrderStatus status={1} />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

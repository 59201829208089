import { forwardRef, useEffect } from 'react'
import { Link, matchPath, useLocation } from 'react-router'

import { useTheme } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

import { NavigationChild } from '@/interfaces/GlobalInterfaces'
import { useMenuStore } from '@/stores/MenuStore.tsx'

export default function NavItem({ item, level }: { item: NavigationChild; level: number }) {
    const theme = useTheme()
    const { isDashboardDrawerOpened: drawerOpen, openedItem, setOpenedItem } = useMenuStore()

    const { pathname } = useLocation()

    const itemTarget = item.target ? '_blank' : '_self'
    const listItemProps = item.external
        ? { component: 'a', href: item.url, target: itemTarget }
        : {
              component: forwardRef<HTMLAnchorElement>((props, ref) => (
                  <Link ref={ref} {...props} to={item.url} target={itemTarget} />
              )),
          }

    const Icon = item.icon
    const itemIcon = Icon ? <Icon style={{ fontSize: '1.25rem' }} /> : null
    const isSelected = !!matchPath({ path: item.url, end: true }, pathname) || openedItem === item.id

    useEffect(() => {
        if (pathname === item.url) setOpenedItem(item.id)
    }, [pathname, item.id])

    const textColor = theme.palette.text.primary
    const iconSelectedColor = theme.palette.mode === 'dark' ? theme.palette.grey['100'] : theme.palette.grey['800']

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => setOpenedItem(item.id)}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: `${level * 28}px`,
                py: level === 1 ? 1.25 : 1,
                '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) },
                '&.Mui-selected': {
                    bgcolor: alpha(theme.palette.primary.main, 0.2),
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                    color: iconSelectedColor,
                    '&:hover': { color: iconSelectedColor, bgcolor: alpha(theme.palette.primary.main, 0.4)},
                },
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': { bgcolor: 'secondary.light' },
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.light',
                                '&:hover': { bgcolor: 'primary.light' },
                            }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                            {item.title}
                        </Typography>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar ? <Avatar>{item.chip.avatar}</Avatar> : <Avatar />}
                />
            )}
        </ListItemButton>
    )
}

export default function ListItemIcon() {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 24,
                },
            },
        },
    }
}

import Typography from '@mui/material/Typography'
import MainCard from '@/components/MainCard'
import { useParams } from 'react-router'
import { ProjectHooks } from '@/api/backend.query.ts'
import { useAppStore } from '@/stores/AppStore.tsx'
import { Grid2 } from '@mui/material'
import { decodeTrie } from '@/services/TraceService.ts'

export default function TraceSinglePage() {
    const params = useParams()
    const { projects } = useAppStore()
    const projectId = params.projectId!
    const project = projects?.find((project) => project.id === projectId)

    const traceId = params.traceId!
    const { data: traceData } = ProjectHooks.getTrace({ projectId, traceId })

    if (!project || !traceData) {
        return <></>
    }
    return (
        <Grid2 container>
            <Grid2 size={12}>
                <MainCard title={project?.name}>
                    <Typography variant="body2">Got</Typography>
                    {/*<pre>{JSON.stringify(traceData.data.data.perf_data.data, null, 2)}</pre>*/}
                    <pre>
                        {JSON.stringify(
                            decodeTrie(
                                traceData.data.data.perf_data.data.map,
                                traceData.data.data.perf_data.data.glossary
                            ),
                            null,
                            4
                        )}
                    </pre>
                </MainCard>
            </Grid2>
        </Grid2>
    )
}
